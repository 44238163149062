import React from "react";
import "./styles.scss";

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-container__copyrights">
        כל הזכויות שמורות אדי הובלות 2021 ©
      </div>
    </div>
  );
}
